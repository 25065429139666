import * as React from "react";

import { useFieldFormContext, ErrorMessage } from "../../common";

export const PaymentCreditCard = () => {
    const { registerField } = useFieldFormContext();

    const fields = {
        cardNumber: {
            id: "cardNumber",
            name: "payment.creditCard.cardNumber",
            options: {
                required: { value: true, message: "Enter a valid credit card number" },
                maxLength: 25,
                minLength: 12
            }
        },
        securityCode: {
            id: "securityCode",
            name: "payment.creditCard.securityCode",
            options: {
                required: { value: true, message: "Enter a security code" },
                maxLength: 4,
                minLength: 3
            }
        },
        expirationMonth: {
            id: "expirationMonth",
            name: "payment.creditCard.expirationMonth",
            options: {
                required: { value: true, message: "Enter an expiration month" },
            }
        },
        expirationYear: {
            id: "expirationYear",
            name: "payment.creditCard.expirationYear",
            options: {
                required: { value: true, message: "Enter an expiration year" }
            },
        }
    }

    // Generate year options for expiration year select starting from the current year
    const getYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 7 }, (_, i) => {
            const year = currentYear + i;
            return <option key={year} value={year}>{year}</option>;
        });

        return years;
    }

    return (
        <>
            <div className="form-group card-number">
                <label htmlFor={fields.cardNumber.id}>Credit Card Number</label>
                <input type="text" {...registerField(fields.cardNumber)}></input>
                <ErrorMessage name={fields.cardNumber.name}/>
            </div>
            <div className="form-group security-code">
                <label htmlFor={fields.securityCode.id}>Security Code</label>
                <input type="text" {...registerField(fields.securityCode)} />
                <ErrorMessage name={fields.securityCode.name} />
            </div>
            <div className="form-group expiration-month">
                <label htmlFor={fields.expirationMonth.id}>Expiration Month</label>
                <select {...registerField(fields.expirationMonth)}>
                    <option value=""></option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
                <ErrorMessage name={fields.expirationMonth.name} />
            </div>
            <div className="form-group expiration-year">
                <label htmlFor={fields.expirationYear.id}>Expiration Year</label>
                <select {...registerField(fields.expirationYear)}>
                    <option value=""></option>
                    {getYearOptions()}
                </select>
                <ErrorMessage name={fields.expirationYear.name} />
            </div>
        </>
    );
}